@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;0,700;1,400;2,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@import "react-datepicker/dist/react-datepicker.css";
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', sans-serif;
}

/*Login Css*/
.budget_table .field_section{
  margin-bottom: 0px;
}
.field_section{
  margin-bottom: 20px;
}
.field_section .chakra-form__label{
  font-size: 12px;
  font-weight: 700;
  color: #000;
  margin-bottom: 5px;
   font-family: 'Open Sans', sans-serif;

}
.field_section label{
  font-size: 12px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
  display: block;
}
.field_section .chakra-input{
  border: 1px solid #ccc;
  height: 43px;
  padding: 10px 15px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}
.field_section .chakra-select{
  border: 1px solid #ccc;
  height: unset;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

/*Login Css*/

/*Header Css*/

.nav_shadow{
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.header_link{
  color: #000 !important;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
}

/*Header Css*/

/*Setting Css*/

.setting_page{
  border-top: 1px solid #ccc;
}
.setting_nav_link{
  font-size: 14px;
  font-weight: 500;
  color: #000 !important;
  font-family: 'Open Sans', sans-serif;
  display: block;
  background-color: #fff;
  padding: 12px 50px;
  width: 96%;
  border-radius: 0px 25px 25px 0px;
}
.setting_nav_link:hover, .setting_nav_link.active{
  background-color: rgba(49, 54, 122, 0.2);
  font-weight: 700;
}
.setting_page_section{
  padding: 20px;
}

.page_section{
  padding-left: 40px;
  padding-right: 40px;
}

/*Setting Css*/

.table_section .chakra-table__container{
  width: 100%;
}
.table_section .chakra-table__container .chakra-table{
  width: 100%;
  border: 1px solid #ccc;
  border-spacing: 0px;
}
.table_section .chakra-table__container .chakra-table thead{
  background-color: #31367A;
  border-spacing: 0px;
}
.table_section .chakra-table__container .chakra-table thead tr th{
  border-spacing: 0px;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  padding: 12px 15px;
  text-align: left;
  text-transform: capitalize;
}
.accordion_section .table_section .chakra-table__container .chakra-table thead{
  background-color: #fff;
  border-spacing: 0px;
}
.accordion_section .table_section .chakra-table__container .chakra-table thead tr th{
  border-spacing: 0px;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  padding: 12px 15px;
  text-align: left;
  text-transform: capitalize;
  border-color: #ccc;

}
.accordion_section .table_section .chakra-table__container .chakra-table{
  width: 100%;
  border: 0px solid #ccc;
  border-top: 1px solid #ccc;
  border-spacing: 0px;
}

.table_section .chakra-table__container .chakra-table tbody tr td{
  border-spacing: 0px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.table_section .chakra-table__container .chakra-table tfoot tr th{
  border-spacing: 0px;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color:#000000;
  padding: 12px 15px;
  text-align: left;
  text-transform: capitalize;
  

}
.table_section .chakra-table__container .chakra-table tbody tr:last-child td{
  border-bottom: 0px;
}
.table_section .chakra-table__container .chakra-table tbody tr td a{
  color: #1400FF;
  text-decoration: none;
}
.inner_table_section .chakra-table__container{
  width: 100%;
}
.inner_table_section .chakra-table__container .chakra-table{
  width: 100%;
  border-top: 1px solid #ccc;
  border-spacing: 0px;
}
.inner_table_section .chakra-table__container .chakra-table thead{
  background-color: transparent;
  border-spacing: 0px;
}
.inner_table_section .chakra-table__container .chakra-table thead tr th{
  border-spacing: 0px;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #585858CC;
  padding: 10px 20px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}
.inner_table_section .chakra-table__container .chakra-table tbody tr td{
  border-spacing: 0px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  padding: 10px 20px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}
.inner_table_section .chakra-table__container .chakra-table tbody tr:last-child td{
  border-bottom: 0px;
}
.inner_table_section .chakra-table__container .chakra-table tbody tr td a{
  color: #1400FF;
  text-decoration: none;
}





/*Project Edit Page*/

.Project_Edit_page_Section{
    padding: 20px 50px;
}

.Project_Edit_page_Section .Backed{
  font-weight: 400;
  font-size: 14px;
  color: #3B86FF;
}
.Project_Edit_page_Section .Backedicon{
  color: #3B86FF;
  font-size: 24px;
}

.TabsList button{
  font-size: 14px;
  font-weight: 400;
   font-family: 'Open Sans', sans-serif;

}
.TabsList button[aria-selected="true"]{
  font-weight: 800;
  color: #31367A ;
  font-family: 'Open Sans', sans-serif;
  height: -19px;
  border-bottom: 3px solid #31367A;
}

#button1{
  margin: 0px 5px;
  width: 140px  ;
  height: 42px;
  color: #787878;
  border: 1px solid #CCCCCC;
  background-color: #fff;
  font-weight: 400;
  border-radius: 7px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

#button2{
  margin: 0px 5px;
  width: 170px  ;
  height: 42px;
  color: #FFFFFF;
  border: 1px solid #31367A;
  background-color: #31367A;
  font-weight: 400;
  border-radius: 7px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

.custom{
  margin-bottom: 20px;
}
.custom label{
  font-size: 12px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
  display: block;
}

.custom .label{
  font-size: 12px;
  font-weight: 700;
  color: #000;
  margin-bottom: 5px;
   font-family: 'Open Sans', sans-serif;

}

.custom input{
  color:black;
  border: 1px solid #ccc;
  height: 43px;
  padding: 10px 15px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}



.Team_Veiw {
/*  padding: 20px;*/
padding-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
}

.Team_Veiw .Backed {
  font-weight: 300;
  font-size: 32px;
   font-family: "Montserrat", sans-serif;

}

.Team_Veiw .Backedicon{
  color: #000000;
  font-size: 30px;
}


.Box_Section .Box_Area{
  font-size: 12px;
  border: 1px solid rgba(49, 54, 122, 0.05);
  background-color: rgba(49, 54, 122, 0.05);
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Box_Section .Box_Area .head_text{
  font-size: 20px;
  font-weight: 400;
}


.Box_Section .Box_Area .Progress div {
 
  background: linear-gradient(to right, rgba(0, 121, 255, 1) 60%, rgba(248, 109, 109, 1) 20%);

}



/*Calender Css*/

.MonthPicker {
  width: 80%;
  border-radius: 10px;
  border:  1px solid black;
  cursor: pointer;
}
input {
  padding: 8px;
}
